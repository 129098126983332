import NavBar from "./components/NavBar";
import Home from "./components/Home";
import SocialLinks from "./components/SocialLinks";
import Programming from "./components/Programming";
import Skills from "./components/Skills";
import Footer from "./components/Footer";
import Illustrations from "./components/Illustrations";
import Design from "./components/Design";

function App() {
  return (
    <div>
      <NavBar />
      <Home />
      <SocialLinks />
      <Skills />
      <Programming />
      <Illustrations />
      <Design />
      <Footer />
    </div>
  );
}

export default App;
